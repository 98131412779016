@import "./base";
@import "./mixins";
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,900|Roboto:400,700,900|Courgette|Blinker|Permanent+Marker|Kaushan+Script&display=swap');
$fa-font-path:"../../node_modules/font-awesome/fonts";
@import "../../node_modules/font-awesome/scss/font-awesome";
@import "../../node_modules/magnific-popup/src/css/main";
@import url("https://use.typekit.net/kbi2xsj.css");
h1,
h2,
h3,
h4 {
  font-family: 'Montserrat';
  font-weight: 900;
}

p {
  font-family: 'Montserrat';
  font-weight: normal;
}

li {
  font-family: 'Montserrat';
  font-weight: normal;
}

body {
  // overflow: hidden;
  // overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  // overflow: hidden;
  overflow-y: scroll;
}

nav.navbar {
  z-index: 9999;
}

.section-content {
  // &:nth-of-type(even){
  padding-top: 80px;
  padding-bottom: 80px;
  // }
}

[v-cloak] {
  display: none !important;
}

.site-navigation {
  position: fixed;
  // top: 10px;
  // right: 15px;
  z-index: 999;
  width: 100%;
  height: 85px;
  overflow: hidden;
  list-style: none;
  transition: all .35s;
  background: #fffafa;
  @include my_bp(md) {
    position: fixed;
    top: auto;
    right: auto;
    height: 85px;
    background: #fffafa;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.16));
  }
  &.show {
    // height: auto;
    ul {
      left: 0;
    }
  }
  &.sticky_header {
    background: #fffafa;
    .hamburger-menu {
      span {
        background: #2f2f2f;
      }
    }
  }
  ul {
    position: fixed;
    top: 0;
    left: -320px;
    z-index: 9999;
    width: 320px;
    // max-width: 90%;
    height: 100vh;
    overflow-x: scroll;
    padding: 30px;
    margin: 0;
    background: #fffafa;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.16));
    transition: all 0.35s;
    @include my_bp(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      height: auto;
      padding: 0;
      overflow: auto;
      background: transparent;
    }
    li {
      display: block;
      padding: 10px 16px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.02rem;
      text-transform: uppercase;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: #191919;
        transform-origin: right top;
        transform: scale(0, 1);
        transition: color 0.1s, transform 0.2s ease-in-out;
      }
      &:hover:before {
        transform-origin: left top;
        transform: scale(1, 1);
      }
      &.current-menu-item {
        &:before {
          transform-origin: left top;
          transform: scale(1, 1);
          background: #ff0;
        }
        a {
          color: #ff0;
          border-color: #2f2f2f;
        }
      }
      a {
        display: block;
        color: #191919;
        transition: all 0.35s;
        text-decoration: none;
        &:hover {
          border-color: #2f2f2f;
        }
      }
    }
  }
  .hamburger-menu {
    position: fixed;
    right: 20px;
    width: 100%;
    max-width: 24px;
    height: 22px;
    margin-left: auto;
    transition: .5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #2f2f2f;
      border-radius: 10px;
      opacity: 1;
      left: 0;
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 2px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 10px;
      }
      &:nth-child(4) {
        top: 18px;
      }
    }
    &.open span {
      &:nth-child(1) {
        top: 18px;
        width: 0;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%;
      }
    }
  }
}

#ksum-footer {
  background: #0b031b;
  .right-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-family: 'Blinker', sans-serif;
      text-align: center;
      color: white;
      font-size: 18px;
      padding-top: 10px;
      font-weight: normal;
    }
    img {
      max-width: 250px;
    }
  }
  .left-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      font-family: 'Blinker', sans-serif;
      text-align: center;
      color: white;
      font-size: 18px;
      padding-top: 10px;
      font-weight: normal;
    }
    .phone {
      font-size: 25px;
      font-weight: bold;
      div {
        text-align: center;
        color: white;
        i {
          color: #027abb;
          margin-right: 10px;
        }
      }
    }
    .social {
      display: flex;
      flex-direction: row;
      justify-content: center;
      a {
        padding: 0 10px;
      }
    }
  }
}

.news {
  text-align: right;
  span {
    font-size: 16px;
    text-align: left;
    color: #8b8b8b;
  }
}

#ksum-copy {
  background: #080113;
  padding: 14px;
  p {
    margin: 0;
    color: white;
    font-family: 'Blinker', sans-serif;
    text-align: center;
  }
}

.buttonDownload {
  display: none;
  position: fixed;
  z-index: 9999;
  border-radius: 5px;
  padding: 10px 25px;
  margin: 10px;
  background-color: #4CC713;
  color: white;
  font-family: sans-serif;
  text-decoration: none;
  font-size: 0.9em;
  text-align: center;
  text-indent: 15px;
  cursor: pointer;
  &:hover {
    background-color: #333;
    color: white;
  }
  &:before,
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 15px;
    top: 52%;
  }
  &:before {
    width: 15px;
    height: 5px;
    border-style: solid;
    border-width: 0 2px 2px;
  }
  &:after {
    width: 0;
    height: 0;
    margin-left: 3px;
    margin-top: -7px;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: transparent;
    border-top-color: inherit;
    animation: downloadArrow 2s linear infinite;
  }
  &:hover:before {
    border-color: #4CC713;
  }
  &:hover:after {
    border-top-color: #4CC713;
  }
}

@keyframes downloadArrow {
  0% {
    margin-top: -7px;
    opacity: 1;
  }
  0.001% {
    margin-top: -15px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    margin-top: 0;
    opacity: 0;
  }
}